"use client";

import { Footer } from "./_components/footer";
import { Header } from "./_components/header";
import { ApolloProvider } from "@apollo/client";
import { initializeApollo } from "@/apollo/client";
import Seo from "./_components/seo";
import { usePathname } from "@/i18n/routing";

// Initialize the Apollo Client instance
const apolloClient = initializeApollo();

export default function Layout({
  children,
}: Readonly<{ children: React.ReactNode }>) {
  const pathname = usePathname();

  // Determine the page based on the current path
  const page = (() => {
    if (pathname === "/") return "Landing";
    if (pathname === "/register/") return "Register";
    if (pathname === "/contact/") return "Contact";
    if (pathname === "/about/") return "About";
    return "Landing"; // Default to "Landing"
  })();

  return (
    <ApolloProvider client={apolloClient}>
      <main className="--font-nunito-sans">
        <Seo page={page} />
        <Header />
        {children}
        <Footer />
      </main>
    </ApolloProvider>
  );
}
