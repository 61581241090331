"use client";
import { GoArrowRight } from "react-icons/go";
import { NavBar } from "./navbar";
import { useState } from "react";
import { motion } from "framer-motion";
import { useTranslations } from "next-intl";
import { Link } from "@/i18n/routing";

export const Header = () => {
  const [hover, setHover] = useState(false);

  const t = useTranslations("TopBand");
  const b = useTranslations("Buttons");

  return (
    <header className="fixed w-full top-0 z-[1]">
      <div className="flex justify-center gap-3 items-center w-full py-3 bg-black text-[#BCBCBC] text-sm">
        <p className="text-[#BCBCBC]/60 hidden md:block">{t.rich("content")}</p>
        <Link
          href="/about"
          aria-label="Learn more about Deskadora's services"
          className="inline-flex gap-1 items-center focus:outline-none focus:ring focus:ring-[#BCBCBC]/70 rounded-md"
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <p>{b.rich("learn_more")}</p>
          <motion.span
            className="inline-flex"
            animate={{ x: hover ? [0, 4, 0] : 0 }}
            transition={{ duration: 0.5 }}
          >
            <GoArrowRight
              className="text-[#BCBCBC] w-4 h-4 inline-flex justify-center items-center"
              aria-hidden="true"
            />
          </motion.span>
        </Link>
      </div>
      <div className="w-full md:px-30 justify-center items-center text-black backdrop-blur-md shadow-md">
        <NavBar />
      </div>
    </header>
  );
};
