/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from "react";
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  split,
  NormalizedCacheObject,
} from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import merge from "deepmerge";
import { onError } from "@apollo/client/link/error";

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;

// HTTP Links for GraphQL Endpoints
const httpLinkStrapi = new HttpLink({
  uri: `${process.env.NEXT_PUBLIC_STRAPI_API_URL}/graphql`,
});

const httpLinkDeskadora = new HttpLink({
  uri: `${process.env.NEXT_PUBLIC_GRAPHQL_BASE_URL}/graphql`,
  credentials: "same-origin",
});

let activeSocket: WebSocket | undefined;
let timedOut: NodeJS.Timeout | undefined;

// WebSocket Link with reconnection handling
const wsLink =
  typeof window !== "undefined"
    ? new GraphQLWsLink(
        createClient({
          url: `${process.env.NEXT_PUBLIC_GRAPHQL_SUBSCRIPTIONS_BASE_URL}/graphql`,
          shouldRetry: () => true,
          retryAttempts: Infinity,
          on: {
            connected: (socket) => {
              // Cast `socket` to WebSocket or assert as type.
              activeSocket = socket as WebSocket;
              console.log("Connected to WebSocket");
            },
            ping: (received: boolean) => {
              if (!received)
                timedOut = setTimeout(() => {
                  if (activeSocket?.readyState === WebSocket.OPEN)
                    activeSocket.close(4408, "Request Timeout");
                }, 5000);
            },
            pong: (received: boolean) => {
              if (received) clearTimeout(timedOut);
            },
          },
        })
      )
    : null;

// Error Handling
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message }) =>
      console.error(`[GraphQL error]: ${message}`)
    );
  if (networkError) console.error(`[Network error]: ${networkError}`);
});

// Authorization Middleware
const authLink = new ApolloLink((operation, forward) => {
  const token = process.env.NEXT_PUBLIC_STRAPI_API_KEY;
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : "",
    },
  });
  return forward(operation);
});

// Multi-endpoint Link
function createIsomorphLink() {
  return ApolloLink.from([
    authLink,
    new ApolloLink((operation, forward) => {
      const token = localStorage.getItem("dsk_auth_token");
      operation.setContext(({ headers = {} }) => ({
        clientName: "deskadora",
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : "",
        },
      }));
      return forward(operation);
    }),
    httpLinkDeskadora,
  ]);
}

// Split Link for subscriptions and HTTP
function createApolloClient(): ApolloClient<NormalizedCacheObject> {
  const splitLink = split(
    (operation) => operation.getContext().clientName === "strapi",
    authLink.concat(httpLinkStrapi),
    authLink.concat(createIsomorphLink())
  );

  const link =
    typeof window !== "undefined" && wsLink
      ? ApolloLink.split(
          ({ query }) => {
            const definition = getMainDefinition(query);
            return (
              definition.kind === "OperationDefinition" &&
              definition.operation === "subscription"
            );
          },
          wsLink,
          splitLink
        )
      : splitLink;

  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: ApolloLink.from([errorLink, link]),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: { fetchPolicy: "network-only" },
      query: { fetchPolicy: "network-only" },
    },
  });
}

// Apollo Client Initialization
export function initializeApollo(
  initialState: any = null
): ApolloClient<NormalizedCacheObject> {
  const _apolloClient = apolloClient ?? createApolloClient();

  if (initialState) {
    const existingCache = _apolloClient.extract();
    const data = merge(initialState, existingCache);
    _apolloClient.cache.restore(data);
  }

  if (typeof window === "undefined") return _apolloClient;
  if (!apolloClient) apolloClient = _apolloClient;
  return _apolloClient;
}

export function useApollo(initialState: any) {
  const store = useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
}
