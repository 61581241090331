import { useTranslations } from "next-intl";

export function useLocaleMetadata(page: string) {
  const t = useTranslations("Metadata");

  // Retrieve the translations for the specific page
  const title = t(`${page}.title`);
  const description = t(`${page}.description`);
  const keywords = t(`${page}.keywords`);

  return {
    title,
    description,
    keywords,
  };
}
